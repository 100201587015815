import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import tw, { styled } from 'twin.macro'
import Banner from "../components/common/Banner"
import { SEO } from "../components/seo"
import DownloadBrochure from "../components/common/DownloadBrochure"

const StyledMainWrapper = styled.section`
${tw`md:(pt-8) pt-0 bg-primary bg-opacity-5`}
.container {
    ${tw`flex flex-col items-center justify-center container mx-auto md:(px-8 py-12) px-5 py-6`};
    p {
        ${tw`md:text-lg text-base font-medium text-muted pb-6 leading-normal`}
    }
    h3 {
        ${tw`text-xl text-secondary font-semibold pb-2`}
    }
    h4 {
        ${tw`text-lg text-orange font-bold pb-2`}
    }
    h5 {
        ${tw`text-lg text-primary-dark font-medium pb-2`}
    }
    ol, ul {
        li {
            ${tw`md:text-lg text-base font-medium text-muted leading-normal`}
            &::marker {
                ${tw`text-lg font-bold text-primary-dark`}
            }
        }
    }
    ol {
      ${tw`list-decimal pl-6`}
      li {
        ${tw`pb-2`}
        p {
          ${tw`pb-2`}
        }

        ul {
            ${tw`pl-4`}
            li {
                &::marker {
                    ${tw`text-lg font-semibold text-primary-dark [content: "> "]`}
                }
            }
        }
      }
    }
    .collaterals {
        ${tw`flex flex-col gap-4`}
        li {
            ${tw`relative pl-6`}
            &:before {
                ${tw`absolute bg-primary-dark w-4 h-4 rounded-full left-0 top-1 [content: '']`}
            }

            &:after {
                ${tw`[content: ''] left-1 top-3 h-2.5 w-1 absolute`}
                transform: rotate(50deg) translate(-50%, -50%);
                border-bottom: 1px solid #fff;
                border-right: 1px solid #fff;
            }

            .sub {
                li {
                    ${tw`ml-6 pl-0 pb-2`}
                    &::marker {
                        ${tw`text-lg font-semibold text-primary-dark [content: "> "]`}
                    }
                    &:before, &:after {
                        ${tw`content-none`}
                    }
                }
            }
        }
    }
}
`;
const hotelOperationDetails = ({ data }) => {
  const { markdownRemark } = data
  const { html, frontmatter } = markdownRemark
  const imgPath = process.env.GATSBY_IMAGE_URL
  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'Our Services', link: '/our-services' },
    { name: 'Hotel Service', link: '/our-service/hotel-service' },
    { name: frontmatter.title, link: '' }
  ]
  return (
    <Layout>
      <Banner
        data={frontmatter.title}
        image={`${imgPath}${frontmatter.slug.toLowerCase()}.jpg`}
        crumbs={crumbs}
      />
      <StyledMainWrapper>
        <div className="container">
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </StyledMainWrapper>
      {frontmatter.downloadBrochure &&
        <DownloadBrochure data={frontmatter.downloadBrochure} />
      }
    </Layout>
  )
};

export default hotelOperationDetails

export const Head = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <SEO title={frontmatter.title} description={frontmatter.description} />
  )
}

export const pageQuery = graphql`
  query HotelOperationsQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 148)
      frontmatter {
        slug
        title
        downloadBrochure {
          publicURL
        }
      }
    }
  }
`
import React from "react"
import StyledDownloadBrochure from "./style/download-brochure"
import { FaArrowDown } from "react-icons/fa"

const DownloadBrochure = ({ data }) => {
    return (
        <StyledDownloadBrochure>
            <div className="main-container">
                <h2>Download Inventory Brochure Here</h2>
                <a href={data.publicURL} target="_blank" rel="noreffer">
                    Download Brochure
                    <FaArrowDown />
                </a>
            </div>
        </StyledDownloadBrochure>
    )
};

export default DownloadBrochure

import tw, { styled } from 'twin.macro';

const StyledDownloadBrochure = styled.section`
${tw`md:py-32 py-12 bg-primary-dark`};
.main-container {
    ${tw`flex flex-col gap-6 items-center justify-center container mx-auto md:px-8 px-5`};
    h2 {
        ${tw`md:text-4xl text-xl font-bold text-center text-white`}
    }
    a {
    ${tw`px-6 py-4 font-semibold border-white border text-white rounded-full flex flex-row gap-2 items-center justify-center md:text-xl text-lg`}
        &:hover {
            ${tw`bg-secondary`}
        }
    }
}
`;

export default StyledDownloadBrochure;
